// import './home.scss';
// console.log('home.js');

document.addEventListener('DOMContentLoaded', function () {
    const button = document.querySelector('.hp-form-btn');
    const formContainer = document.querySelector('.form-con');
  
    button.addEventListener('click', function (e) {
      e.preventDefault(); 
      
      button.classList.toggle('active');
      formContainer.classList.toggle('active');
    });
});
  